@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap");
$text-font: "Josefin Sans", sans-serif;

.Hero {
  width: 100%;
  height: 100%;
  font-family: $text-font;
}
.hero-img {
  background-image: url("../assets/HeroBlack.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  background-position: center;
}

// Navbar

.hero-navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
  position: fixed;
  transition: all 0.5s ease;
  z-index: 2;
}
.hero-navbar-active {
  background-color: rgba(black, 0.6);
  height: 160px;
}

.hero-hamb-top {
  margin-top: 10px;
}
.hero-ul {
  list-style: none;
  display: flex;
  width: 800px;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  li {
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    width: 180px;
    padding: 0px 10px 0px 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    position: relative;

    p {
      text-decoration: none;
      color: white;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    // ANIMATIONS FOR THE UNDERLINE HOVER

    p:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 2px;
      content: "";
      display: block;
      height: 1.5px;
      left: 50%;
      position: absolute;
      background: #fff;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    p:before {
      background: none repeat scroll 0 0 transparent;
      bottom: 2px;
      content: "";
      display: block;
      height: 1.5px;
      left: 50%;
      margin-left: -1px;
      position: absolute;
      background: #fff;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    p:hover:after {
      width: 50%;
      left: 0%;
    }
    p:hover:before {
      width: 50%;
      left: 50%;
    }
  }
}
.hero-ul {
  li {
    .active-link {
      cursor: default;
      &:after {
        width: 50%;
        left: 0%;
      }
      &:before {
        width: 50%;
      }
    }
  }
}

.hero-hamburger {
  display: flex;
  width: 35px;
  height: 30px;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.5s ease;
  display: none;
}
.hero-hamburger-active {
  .line01 {
    transform: rotate(45deg) translateY(7px) translateX(7px);
  }
  .line02 {
    transform: rotate(135deg) translateY(-2px) translateX(3px);
  }
  .line03 {
    transform: rotate(135deg) translateY(2.9px) translateX(-2.5px);
    opacity: 0;
  }
}
.line {
  transition: all 0.5s ease;
  height: 1px;
  background-color: white;
  width: 25px;
  margin-top: 6px;
}

.hero-ul-1 {
}
.hero-ul-2 {
}
.hero-logo {
  margin-right: -35px;
  img {
    width: 150px;
  }
}
.hero-navbar-line {
  width: 600px;
  height: 1.5px;
  border: none;
  background-color: white;
  position: absolute;
  bottom: 18px;
}

// Middle text

.hero-middle-text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  padding: 50px;
  font-size: 40px;
  color: white;
  text-align: center;
}

.hero-arrow-down {
  width: 45px;
  height: 45px;
  border: 1px solid white;
  transition: all 0.5s ease;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 15px;
    transition: all 0.5s ease;
  }
  &:hover {
    transform: translateX(-50%) rotate(360deg) scale(1.1);
  }
}
@media screen and (max-width: 1100px) {
  .hero-logo {
    margin-right: -15px;
    img {
      width: 120px;
    }
  }
  .hero-navbar-line {
    width: 500px;
  }
  .hero-ul {
    li {
      width: 140px;
    }
  }
  .hero-middle-text {
    width: 70%;
  }
  .hero-navbar-active {
    height: 140px;
  }
}
@media screen and (max-width: 860px) {
  .hero-hamburger {
    display: flex;
    position: absolute;
    right: 25px;
    top: 55px;
  }
  .hero-ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 130px;
    background-color: rgba(black, 0.6);
    width: 250px;
    transform: translateX(250px);
    transition: all 0.5s ease;
  }
  .hero-ul-active {
    transform: translateX(0px);
  }

  .hero-ul-a-1 {
    transition: all 0.5s ease;
    background-color: rgba(black, 0.6);
    position: absolute;
    right: 0px;
    height: 265px;
    transform: translateX(250px);
    width: 250px;
    top: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    li {
      &:nth-child(2) {
        margin-top: 21px;
      }
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      width: 140px;
      padding: 0px 10px 0px 10px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;
      position: relative;

      p {
        text-decoration: none;
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }

      // ANIMATIONS FOR THE UNDERLINE HOVER

      p:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 2px;
        content: "";
        display: block;
        height: 1.5px;
        left: 50%;
        position: absolute;
        background: #fff;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      p:before {
        background: none repeat scroll 0 0 transparent;
        bottom: 2px;
        content: "";
        display: block;
        height: 1.5px;
        left: 50%;
        margin-left: -1px;
        position: absolute;
        background: #fff;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      p:hover:after {
        width: 50%;
        left: 0%;
      }
      p:hover:before {
        width: 50%;
        left: 50%;
      }
    }
  }
  .hero-ul-a-1-active {
    transform: translateX(0px);
  }
  .hero-ul-1 {
    height: 150px;
  }
  .hero-ul-2 {
    display: flex;
    justify-content: flex-start;
    height: 140px;
    margin-top: 150px;
    li:nth-child(2) {
      margin-top: 18px;
    }
  }
  .hero-ul-2-a {
    margin-top: 135px;
  }
  .hero-navbar {
    justify-content: flex-start;
    height: 160px;
  }
  .hero-logo {
    margin-left: 25px;
    margin-top: 20px;
  }
  .hero-navbar-line {
    left: 25px;
    width: 300px;
    display: none;
  }
  .hero-middle-text {
    font-size: 35px;
    width: 80%;
  }
  .hero-navbar-active {
    height: 130px;
  }
}
@media screen and (max-width: 600px) {
  .hero-img {
    background-position: right;
  }
}
@media screen and (max-width: 550px) {
  .hero-middle-text {
    width: 90%;
    font-size: 30px;
  }
}
@media screen and (max-width: 440px) {
  .hero-ul-a-1 {
    width: 200px;
  }
  .hero-ul {
    width: 200px;
  }
}
@media screen and (max-width: 330px) {
  .hero-ul-a-1 {
    width: 180px;
    li {
      font-size: 13px;
    }
  }
  .hero-ul {
    width: 180px;
    li {
      font-size: 13px;
    }
  }
}
