@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap");
$text-font: "Josefin Sans", sans-serif;

.KP {
	font-family: $text-font;
	padding: 0px 100px 80px 100px;
}

.kp-title {
	font-size: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 100px 0px 80px 0px;
}

.kp-img {
	img {
		width: 450px;
	}
}

.kp-content {
	display: flex;
	justify-content: space-evenly;
}
.kp-grid {
	margin-top: 80px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-left: 100px;
	margin-right: 100px;
}
.em-grid {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.kp-g {
	width: 300px;
	height: 300px;
	background-color: #222222;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	color: white;
}
.kpg-title {
	font-size: 20px;
	height: 62px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
.kpg-price {
	font-size: 30px;
	width: 100%;
	display: flex;
	height: 50px;
	justify-content: center;
	align-items: center;
}
.kpg-hr {
	width: 150px;
}
.kpg-desc {
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 220px;
	text-align: center;
}
.premium {
	width: 220px;
	font-size: 16px !important;
	margin: 0 auto;
	text-align: center;
}
.kpg-btn {
	outline: none;
	width: 150px;
	font-family: $text-font;
	height: 45px;
	border: none;
	background-color: white;
	color: #222222;
	font-size: 12px;
	cursor: pointer;
	transition: all 0.5s ease;
	border: 1px solid white;
	background: none;
	color: white;
	&:hover {
		border: none;
		background-color: white;
		color: #222222;
	}
}
@media screen and (max-width: 1450px) {
	.kp-grid {
		margin: 0px;
		margin-top: 80px;
	}
	.kp-g {
		width: 250px;
		height: 250px;
	}
	.kpg-title {
		height: 50px;
		font-size: 18px;
	}
	.kpg-price {
		font-size: 25px;
	}
	.kpg-desc {
		height: 80px;
		font-size: 15px;
		width: 200px;
	}
	.kpg-btn {
		width: 140px;
		height: 40px;
	}
}
@media screen and (max-width: 1250px) {
	.kp-img {
		img {
			width: 400px;
		}
	}
}
@media screen and (max-width: 1000px) {
	.kp-img {
		img {
			width: 350px;
		}
	}
	.kp-grid {
		flex-direction: column;
		height: 900px;
		margin-top: 0px;
	}
	.em-grid {
		flex-direction: column;
		height: auto;
		padding: 3rem 0rem;
		row-gap: 40px;
		margin-top: 0px;
	}
}
@media screen and (max-width: 920px) {
	.kp-content {
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.kp-2 {
		margin-top: 50px;
	}
}
@media screen and (max-width: 490px) {
	.kp-title {
		font-size: 25px;
	}
	.kp-img {
		img {
			width: 300px;
		}
	}
}
@media screen and (max-width: 400px) {
	.kp-title {
		font-size: 20px;
	}
	.kp-img {
		img {
			width: 250px;
		}
	}
}
