@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap");
$text-font: "Josefin Sans", sans-serif;
.KontaktC {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 75px 150px 75px 150px;
  font-family: $text-font;
  color: #222222;
  font-weight: lighter;
  justify-content: center;
}
.kc-1 {
  width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.kc-1-name {
  width: 440px;
  display: flex;
  .kc-1-first-name {
    input {
      width: 200px;
    }
  }
  .kc-1-last-name {
    margin-left: 40px;
    input {
      width: 200px;
    }
  }
}
.kc-1-title {
  font-size: 35px;
  font-weight: normal;
  padding-right: 50px;
}
.kc-1-desc {
  font-size: 18px;
}
.kc-1-question {
  margin-top: 50px;

  margin-bottom: 50px;
  .kc-q-btn {
    transition: all 0.5s ease;
    width: 200px;
    height: 45px;
    border: 1px solid #222222;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    font-family: $text-font;
    font-size: 14px;
  }
  .kc-1-q-Terminanfrage {
    margin-left: 40px;
  }
  .kc-btn-active {
    background-color: #222222;
    color: white;
  }
}

.kc-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #222222;
  height: 40px;
  transition: all 0.5s ease;
  font-family: $text-font;
  &::placeholder {
    color: #222222;
    transition: all 0.5s ease;
    opacity: 0.7;
  }
  outline: none;
  &:focus {
    transform: translateY(-5px);
    &::placeholder {
      opacity: 0;
    }
  }
}
.kc-2 {
  width: 500px;
  img {
    width: 750px;
  }
}
.kc-1-email {
  margin-top: 25px;
  input {
    width: 440px;
  }
}
.kc-1-phone {
  margin-top: 25px;
  input {
    width: 440px;
  }
}
.kc-1-date {
  margin-top: 25px;
  display: flex;
  align-items: center;
  width: 440px;
  font-size: 20px;

  .date {
    margin-left: 40px;
    color: #222222;
    font-size: 15px;
    width: 125px;
    text-align: center;
    height: 45px;
    border: none;
    display: flex;
  }
}
.kc-1-button {
  margin-top: 25px;
  .kc-1-button-sended {
    outline: none;
    width: 125px;
    height: 40px;
    background-color: #222222;
    border: none;
    color: white;
    cursor: pointer;
    font-family: $text-font;
  }
}
.kc-1-choose {
  margin-top: 25px;
  font-size: 20px;
}
.kc-1-c-each {
  display: flex;
  margin-top: 10px;
  align-items: center;
  input {
    width: 16px;
    height: 16px;
  }
  p {
    margin-left: 10px;
    font-size: 17px;
  }
}
.kc-1-message {
  margin-top: 28px;
  textarea {
    width: 440px;
    height: 150px;
    border: none;
    border-bottom: 1px solid #222222;
    outline: none;
    font-family: $text-font;
  }
}
//fragen pitanje

@media screen and (max-width: 1800px) {
  .kc-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 600px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .kc-2 {
    margin-left: 25px;
    img {
      width: 500px;
    }
  }
}
@media screen and (max-width: 1370px) {
  .kc-2 {
    margin-left: 100px;
  }
}
@media screen and (max-width: 1250px) {
  .kc-1 {
  }
  .kc-1-title {
    font-size: 30px;
    padding: 0px;
  }
  .kc-1-desc {
    font-size: 16px;
  }
  .kc-1-question {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 390px;
    .kc-q-btn {
      width: 175px;
      height: 42px;
    }
  }
  .kc-1-name {
    width: 390px;
    .kc-1-first-name {
      input {
        width: 175px;
      }
    }
    .kc-1-last-name {
      input {
        width: 175px;
      }
    }
  }
  .kc-1-email {
    input {
      width: 390px;
    }
  }
  .kc-1-message {
    textarea {
      width: 390px;
    }
  }
  .kc-1-phone {
    input {
      width: 390px;
    }
  }
  .kc-1-date {
    width: 390px;
  }
}
@media screen and (max-width: 1150px) {
  .KontaktC {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .kc-1 {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .kc-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0px;
    margin-top: 75px;

    align-items: center;
  }
  .kc-1-choose {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 390px;
    .kc-1-choose-title {
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 630px) {
  .KontaktC {
    padding: 50px 75px 50px 75px;
  }
  .kc-2 {
    img {
      width: 400px;
    }
  }
}
@media screen and (max-width: 520px) {
  .kc-1-title {
    font-size: 28px;
  }
  .kc-1-desc {
    font-size: 15px;
  }
  .kc-1-question {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 320px;
    .kc-q-btn {
      width: 150px;
      height: 40px;
      font-size: 12px;
    }
    .kc-1-q-Terminanfrage {
      margin-left: 20px;
    }
  }
  .kc-1-name {
    width: 320px;
    flex-direction: column;
    .kc-1-first-name {
      input {
        width: 320px;
      }
    }
    .kc-1-last-name {
      margin: 0px;
      margin-top: 25px;
      input {
        width: 320px;
      }
    }
  }
  .kc-1-email {
    input {
      width: 320px;
    }
  }
  .kc-1-message {
    textarea {
      width: 320px;
    }
  }
  .kc-1-phone {
    input {
      width: 320px;
    }
  }
  .kc-1-date {
    width: 320px;
  }
  .kc-1-choose {
    width: 320px;
  }
  .kc-2 {
    img {
      width: 320px;
    }
  }
}
@media screen and (max-width: 420px) {
  .kc-1-title {
    font-size: 25px;
  }
  .kc-1-desc {
    font-size: 15px;
  }
  .kc-1-question {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .kc-q-btn {
      width: 150px;
    }
    .kc-1-q-Terminanfrage {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
  .kc-1-name {
    width: 250px;
    flex-direction: column;
    .kc-1-first-name {
      input {
        width: 250px;
      }
    }
    .kc-1-last-name {
      margin: 0px;
      margin-top: 25px;
      input {
        width: 250px;
      }
    }
  }
  .kc-1-email {
    input {
      width: 250px;
    }
  }
  .kc-1-message {
    textarea {
      width: 250px;
    }
  }
  .kc-1-phone {
    input {
      width: 250px;
    }
  }
  .kc-1-date {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: centr;
    align-items: center;
    p {
      width: 250px;
      margin-bottom: 10px;
      font-size: 19px;
    }
    .date {
      height: 40px;
      width: 150px;
      margin: 0px;
    }
  }
  .kc-1-choose {
    width: 250px;
    font-size: 19px;
    .kc-1-c-each {
      p {
        font-size: 16px;
      }
    }
  }
  .kc-2 {
    img {
      width: 250px;
    }
  }
}

.fieldError {
  color: red;
  margin: 7px 3px;
  text-align: left;
  //float: left;
  font-size: 16px;
}
