@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap");
$text-font: "Josefin Sans", sans-serif;
.WhyUs {
	width: 100%;
	font-family: $text-font;
	color: #222222;
	padding: 0px 350px 0px 350px;
}
.wie {
	width: 100%;
	font-family: $text-font;
	color: #222222;
	padding: 0px 350px 0px 350px;
}
.WhyUsCustom {
	margin-top: 35px;
}
.wu-title {
	width: 100%;
	height: 40px;
	font-size: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.eu-title {
	width: 100%;
	height: 40px;
	font-size: 30px;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
}
.wu-content {
	height: 400px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 50px;
}
.em-content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin-bottom: 100px;
	text-align: center;
	grid-gap: 20px;
}
.wu-div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	width: 300px;
	border-bottom: 1.5px solid #222222;
}
.em-div {
	text-align: center;
	width: 300px;
	border-bottom: 1.5px solid transparent;
}

.wu-upper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 50%;
	width: 100%;
	img {
		width: 70px;
		margin-bottom: 15px;
	}
	p {
		font-size: 20px;
	}
	.wu-img-1 {
		width: 70px;
	}
	.wu-img-2 {
		width: 55px;
	}
	.wu-img-3 {
		width: 55px;
	}
}
.em-upper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-top: 2rem;
	height: 10%;
	width: 100%;
	img {
		width: 70px;
		margin-bottom: 15px;
	}
	p {
		font-size: 20px;
	}
	.wu-img-1 {
		width: 70px;
	}
	.wu-img-2 {
		width: 55px;
	}
	.wu-img-3 {
		width: 55px;
	}
}
.wu-bottom {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 30%;
	text-align: center;
	padding: 0px 25px 0px 25px;
	flex-direction: column;
	height: 40%;
}
.eu-bottom,
.scroll {
	text-align: center;
	padding: 20px 25px 20px 25px;
}

// .eu-bottom::-webkit-scrollbar-track {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// 	background-color: #f5f5f5;
// }

// .eu-bottom::-webkit-scrollbar {
// 	width: 6px;

// 	background-color: #f5f5f5;
// }

// .eu-bottom::-webkit-scrollbar-thumb {
// 	background-color: #000000;
// 	border-radius: 10px;
// }

@media screen and (max-width: 1800px) {
	.WhyUs,
	.wie {
		padding: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.wu-content {
		width: 1200px;
	}
}
@media screen and (max-width: 1300px) {
	.wu-content {
		width: 1050px;
	}
}
@media screen and (max-width: 1200px) {
	.wu-content {
		height: 300px;
		margin-top: 25px;
	}
	.wu-div {
		height: 250px;
		width: 250px;
	}
	.wu-upper {
		img {
			width: 50px;
		}
		p {
			padding: 0px 15px 0px 15px;
		}
		.wu-img-1 {
			width: 60px;
		}
		.wu-img-2 {
			width: 45px;
		}
		.wu-img-3 {
			width: 45px;
		}
	}
}
@media screen and (max-width: 1150px) {
	.WhyUs,
	.wie {
		margin-top: -20px;
	}
}
@media screen and (max-width: 1100px) {
	.wu-custom {
		font-size: 30px;
		padding: 0px 50px 0px 50px;
		text-align: center;
	}
	.wu-content {
		height: 250px;
		width: 850px;
	}
	.wu-div {
		width: 200px;
		height: 200px;
	}
	.wu-upper {
		p {
			font-size: 17px;
		}

		.wu-img-1 {
			width: 55px;
		}
		.wu-img-2 {
			width: 40px;
		}
		.wu-img-3 {
			width: 40px;
		}
	}
	.wu-bottom {
		p {
			font-size: 13px;
		}
	}
}
@media screen and (max-width: 1000px) {
	.wu-content {
		flex-direction: column;
		height: 1200px;
	}
	.em-content {
		grid-template-columns: 1fr;
		height: 1200px;
		margin-bottom: 30px;
	}
	.WhyUs,
	.wie {
		margin-top: 0px;
	}
	.wie {
		padding: 0px 50px 0px 50px;
	}
	.em-div {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: auto;
		text-align: center;
		margin: 0 auto;
		border-bottom: 1.5px solid #222222;
		padding-bottom: 10px;
	}
}
@media screen and (max-width: 990px) {
}
@media screen and (max-width: 870px) {
	.wu-content {
		width: 100%;
	}
}
@media screen and (max-width: 489px) {
	.wu-title {
		font-size: 25px;
	}
}
@media screen and (max-width: 400px) {
}
