@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap");
$text-font: "Josefin Sans", sans-serif;
.Footer {
  width: 100%;
  font-family: $text-font;
  height: 100%;
  color: white;
}
.f-upper {
  height: 300px;
  width: 100%;
  background-color: #222222;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.fu-logo {
  margin-bottom: 25px;
  img {
    padding-left: 25px;
    width: 170px;
  }
}
.fu-desc {
  width: 700px;
  font-size: 20px;
  margin-bottom: 0px;
}
.fu-bottom {
  display: flex;
  justify-content: space-evenly;
  width: 300px;
  height: 100px;
  padding-top: 12px;
}
.fu-bottom-inner {
  cursor: pointer;
}
.fub,
.fu-bottom-inner {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: center;
  justify-content: center;
  p {
    font-weight: lighter;
    font-size: 15px;
    margin-left: 8px;
  }
  a {
    font-weight: lighter;
    font-size: 15px;
    margin-left: 8px;
    text-decoration: none;
    color: white;
  }
  img {
    width: 32px;
  }
}
.fub-1 {
  img {
    width: 26px;
    padding-bottom: 2px;
  }
}
.fub-3 {
  img {
    width: 26px;
  }
}
.f-bottom {
  height: 45px;
  position: relative;

  width: 100%;
  background-color: #181818;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-weight: lighter;
    padding: 0px 30px 0px 30px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
  }
}
.f-hr {
  display: flex;
  justify-content: center;
  background-color: white;
  border: none;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
@media screen and (max-width: 1000px) {
  .f-upper {
    height: 230px;
  }
  .fu-logo {
    margin-bottom: 15px;
    img {
      width: 150px;
    }
  }
  .fu-desc {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .fu-bottom {
    height: 50px;
    padding-top: 0px;
  }
}
@media screen and (max-width: 820px) {
  .fub,
  .fu-bottom {
    img {
      width: 28px;
    }
  }
  .fub-1 {
    img {
      width: 23px;
    }
  }
  .fub-3 {
    img {
      width: 24px;
    }
  }
}
@media screen and (max-width: 750px) {
  .f-upper {
    height: 226px;
  }
  .fu-bottom {
  }
  .f-hr {
    width: 350px;
  }
  .fu-desc {
    font-size: 18px;
    width: 500px;
  }
  .fu-logo {
    margin-bottom: 15px;
    margin-left: -10px;
    img {
      width: 140px;
    }
  }
}
@media screen and (max-width: 550px) {
  .fu-desc {
    padding: 0px 45px 0px 45px;
    width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .fu-bottom {
    width: 230px;
  }
  .fu-desc {
    font-size: 16px;
    padding: 0px 30px 0px 30px;
    margin-bottom: 25px;
  }
  .f-hr {
    width: 220px;
  }
  .f-bottom {
    p {
      font-size: 13px;
    }
  }
}
@media screen and (max-width: 318px) {
  .f-upper {
    height: 245px;
  }
}
