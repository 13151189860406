@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap");
$text-font: "Josefin Sans", sans-serif;

.ABeauty {
	width: 100%;
	height: 600px;
	display: flex;
	align-items: center;
	font-family: $text-font;
	padding: 50px 100px 50px 100px;
	color: #222222;
	display: flex;
	justify-content: center;
	position: relative;
}
#ABEAUTY_ID {
	width: 100px;
	height: 50px;
	top: -160px;
	position: absolute;
	visibility: hidden;
}
.ab-1-div {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 500px;
	img {
		width: 500px;
	}
}
.ab-2-div {
	margin-left: 60px;
	width: 500px;
	height: 65%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.ab-name {
	font-size: 20px;
	hr {
		border: none;
		width: 50px;
		background-color: #222222;
		height: 2px;
	}
}
.ab-title {
	margin-top: 25px;
	font-size: 28px;
}
.ab-info {
	margin-top: 25px;
	letter-spacing: 1px;
	font-size: 17px;
	font-weight: lighter;
}
@media screen and (max-width: 1570px) {
	.ABeauty {
		height: 600px;
	}
	.ab-1-div {
		img {
			width: 85%;
		}
	}
	.ab-2-div {
		width: 500px;
		margin-left: 25px;
		height: 100%;
	}
}
@media screen and (max-width: 1370px) {
	.ab-title {
		margin-top: 25px;
	}
	.ab-info {
		margin-top: 25px;
	}
}
@media screen and (max-width: 1230px) {
	.ab-2-div {
		width: 525px;
	}
	.ab-name {
		font-size: 16px;
	}
	.ab-title {
		font-size: 20px;
	}
	.ab-info {
		font-size: 16px;
	}
}
@media screen and (max-width: 1100px) {
	#ABEAUTY_ID {
		top: -140px;
	}
}
@media screen and (max-width: 1050px) {
	.ABeauty {
		flex-direction: column;
		width: 100%;
		height: 750px;
	}
	.ab-1-div {
		margin-top: 50px;
		margin-right: 25px;
		img {
			width: 400px;
		}
	}
	.ab-2-div {
		margin-left: 0px;

		order: -1;
	}
}
@media screen and (max-width: 860px) {
	#ABEAUTY_ID {
		top: -130px;
	}
}
@media screen and (max-width: 630px) {
	.ABeauty {
		padding: 0px;
		height: 100%;
	}
	.ab-1-div {
		width: 100%;
		padding: 0px;
		margin: 0px;
		padding: 50px 75px 50px 75px;

		img {
			width: 100%;
			height: 100%;
		}
	}
	.ab-2-div {
		width: 100%;
		padding: 60px 75px 0px 75px;
	}
}
@media screen and (max-width: 400px) {
	.ab-2-div {
		padding: 45px 50px 0px 50px;
	}
	.ab-1-div {
		padding: 50px 50px 50px 50px;
	}
}
@media screen and (max-width: 350px) {
	.ab-2-div {
		padding: 30px 30px 0px 30px;
	}
	.ab-1-div {
		padding: 30px 30px 30px 30px;
	}
}
