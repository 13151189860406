@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap");
$text-font: "Josefin Sans", sans-serif;

.KQ {
  width: 100%;
  height: 100%;
  padding: 0px 0px 80px 0px;
}

.kq-title {
  width: 100%;
  height: 85px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $text-font;
}
.kq-divs {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.kq-1 {
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.kq-2 {
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1150px) {
  .kq-divs {
    flex-direction: column;
  }
  .kq-1 {
    width: 100%;
  }
  .kq-2 {
    width: 100%;
  }
}

@media screen and (max-width: 490px) {
  .kq-title {
    font-size: 25px;
    padding: 0px 120px 0px 120px;
    text-align: center;
  }
}
