@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap");
$text-font: "Josefin Sans", sans-serif;

.rq {
  width: 400px;
  background-color: #222222;
  height: 100%;
  font-family: $text-font;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 35px;
  flex-direction: column;
  transition: all 0.5s ease;
}

.rq-title {
  color: white;
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
  padding-right: 70px;
  align-items: center;
}
.rq-img {
  position: absolute;
  right: 42.5px;
  cursor: pointer;
  top: 38px;
  transform: translate(50%);
  transition: all 0.5s ease;
}
.rq-img-active {
  transform: rotate(180deg) translateX(-10px);
}
.rq-desc {
  width: 100%;
  background-color: #222222;
  color: white;
  font-weight: lighter;
  transition: all 0.5s ease;
  max-height: 0px;
  padding: 0px 15px 0px 15px;
  opacity: 0;
}
.kryo-img {
  margin-top: 15px;
  margin-bottom: 12px;
  width: 370px;
}
.rq-desc-active {
  padding-left: 15px;
  padding-right: 15px;
  max-height: 1000px;
  opacity: 1;
  overflow: visible;
}
@media screen and (max-width: 1250px) {
  .rq {
    width: 350px;
  }
  .kryo-img {
    width: 319px;
  }
  .rq-title {
    font-size: 15px;
  }
}

@media screen and (max-width: 450px) {
  .rq {
    width: 300px;
  }
  .kryo-img {
    width: 270px;
  }
}
@media screen and (max-width: 360px) {
  .rq {
    width: 270px;
  }
  .kryo-img {
    width: 240px;
  }
}
